
// $info: #26648E;
// $danger: #E65C4F;

// $success: #61AF25;

$enable-gradients: true;

@import '~bootstrap/scss/bootstrap.scss'

